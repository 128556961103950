// src/components/SearchComponent.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import { supabase } from "../supabaseClient";
import {
  FaSpinner,
  FaPaperclip,
  FaTimes,
  FaHeart,
  FaShare,
  FaSearch,
  FaCalendarAlt,
  FaTag,
  FaCar,
  FaDollarSign,
  FaBolt,
  FaGasPump,
  FaRoad,
  FaEnvelope,
  FaExclamation,
  FaFireAlt,
  FaFilter, // Importa FaFilter aquí
} from "react-icons/fa";
import {
  FiSearch,
  FiHeart as FiHeartOutline,
  FiUser,
  FiArrowUp,
  FiMenu,
  FiX,
  FiChevronDown,
  FiChevronUp,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";
import ChatbotButton from "./ChatbotButton";
import { parseUserInput, getCarInfo, allowedColors } from "../utils/searchUtils";

const SearchComponent = () => {
  // Estados Principales
  const [vehicles, setVehicles] = useState([]);
  const [query, setQuery] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [likedCars, setLikedCars] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [selectedCar, setSelectedCar] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState("inicio");
  const [scrollPosition, setScrollPosition] = useState(0);

  const fileInputRef = useRef(null);
  const modelInputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Estados para la paginación
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const pageSize = 20; // 20 vehículos por página

  // Estado para determinar si es móvil
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isChatbotOpen, setIsChatbotOpen] = useState(false); // Estado para controlar el chatbot

  // Estado para mantener el último modelo consultado
  const [lastModel, setLastModel] = useState(null);

  // Estado para los modelos disponibles
  const [availableModels, setAvailableModels] = useState([]);

  // Estado para filtros avanzados
  const [advancedFilters, setAdvancedFilters] = useState({
    maxPrice: "",
    minYear: "",
    maxYear: "",
    combustible: "",
    sortOrder: "asc",
    minKilometraje: "",
    maxKilometraje: "",
    model: "",
  });

  // Estado para sugerencias de búsqueda
  const [suggestions, setSuggestions] = useState([]);

  // Estados para mostrar/ocultar la búsqueda avanzada
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false); // Añadido

  // Lista de marcas de coches
  const carBrands = [
    "Ferrari",
    "Lamborghini",
    "Porsche",
    "BMW",
    "Mercedes-Benz",
    "Audi",
    "Tesla",
    "McLaren",
    "Mustang",
  ];

  // Búsquedas frecuentes para sugerencias
  const frequentSearches = ["Porsche 911", "Tesla Model S", "Ferrari California", "BMW M3"];

  // Función para obtener los vehículos
  const fetchVehicles = useCallback(async () => {
    setIsLoading(true);

    try {
      console.log("fetchVehicles - query:", query);

      // Procesar la consulta usando parseUserInput
      const { model, year, color, sortPrice, sortYear } = parseUserInput(
        query,
        availableModels,
        lastModel
      );

      // Extraer los filtros avanzados
      const {
        maxPrice,
        minYear,
        maxYear,
        combustible,
        sortOrder,
        minKilometraje,
        maxKilometraje,
        model: advancedModel,
      } = advancedFilters;

      // Realizar la consulta con los filtros configurados por el usuario
      const { data: cars, count } = await getCarInfo(
        {
          model: advancedModel || model,
          year,
          color,
          sortPrice: sortPrice || sortOrder, // Priorizar el filtro sortOrder si sortPrice no está definido
          sortYear,
          combustible, // Aplicar el filtro de combustible (como "Eléctrico")
          maxPrice,
          minYear,
          maxYear,
          minKilometraje,
          maxKilometraje,
        },
        currentPage,
        pageSize
      );

      if (cars && cars.length > 0) {
        setVehicles(cars);
        setTotalPages(Math.ceil(count / pageSize));
      } else {
        setVehicles([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error inesperado:", error);
      setVehicles([]);
      setTotalPages(1);
    }

    setIsLoading(false);
  }, [query, currentPage, availableModels, lastModel, advancedFilters]);

  useEffect(() => {
    fetchVehicles();

    const channel = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "VEHÍCULOS2_duplicate con datos" }, // Cambiar aquí
        (payload) => {
          fetchVehicles();
        }
      )
      .subscribe();

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        modelInputRef.current &&
        !modelInputRef.current.contains(event.target)
      ) {
        setIsModelDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      channel.unsubscribe();
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentPage, fetchVehicles]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Cargar la lista de modelos desde Supabase al montar el componente
  useEffect(() => {
    const fetchModels = async () => {
      const { data, error } = await supabase
        .from("VEHÍCULOS2_duplicate con datos") // Cambiar aquí
        .select("model") // Cambiar "model" si el nombre de la columna ha cambiado
        .neq("model", "")
        .order("model", { ascending: true });

      if (error) {
        console.error("Error al obtener modelos de Supabase:", error);
        setAvailableModels([]);
      } else {
        // Extraer modelos únicos y ordenarlos por longitud descendente
        const uniqueModels = [...new Set(data.map((item) => item.model.toLowerCase()))];
        uniqueModels.sort((a, b) => b.length - a.length); // Ordenar por longitud descendente
        setAvailableModels(uniqueModels);
        console.log("Modelos disponibles:", uniqueModels);
      }
    };

    fetchModels();
  }, []);

  // Implementar debounce en la búsqueda
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchVehicles();
    }, 500); // Retraso de 500 ms

    return () => clearTimeout(delayDebounceFn);
  }, [query, currentPage, fetchVehicles]);

  // Manejar Subida de Archivos
  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newUploadedFiles = files.map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      type: file.type,
      size: file.size,
      preview: file.type.startsWith("image/") ? URL.createObjectURL(file) : null,
    }));
    setUploadedFiles((prev) => [...prev, ...newUploadedFiles]);
  };

  // Eliminar Archivo Subido
  const removeFile = (fileId) => {
    setUploadedFiles((prev) => prev.filter((file) => file.id !== fileId));
  };

  // Previsualización de Archivos Subidos
  const FilePreview = () => (
    <AnimatePresence>
      {uploadedFiles.length > 0 && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 p-4 bg-gray-800/50 rounded-xl backdrop-blur-xl border border-gray-700/50"
        >
          <div className="flex flex-wrap gap-4">
            {uploadedFiles.map((file) => (
              <div key={file.id} className="relative group">
                {file.preview ? (
                  <div className="relative w-24 h-24 rounded-lg overflow-hidden">
                    <img
                      src={file.preview}
                      alt={file.name}
                      className="w-full h-full object-cover"
                      loading="lazy"
                    />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      aria-label={`Eliminar archivo ${file.name}`}
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                ) : (
                  <div className="w-24 h-24 rounded-lg bg-gray-700 flex items-center justify-center relative">
                    <FaCar className="text-2xl text-gray-400" />
                    <button
                      onClick={() => removeFile(file.id)}
                      className="absolute top-1 right-1 p-1 bg-red-500 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      aria-label={`Eliminar archivo ${file.name}`}
                    >
                      <FaTimes className="text-white text-sm" />
                    </button>
                  </div>
                )}
                <p className="mt-1 text-xs text-gray-400 truncate max-w-[96px]">
                  {file.name}
                </p>
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Manejar "Me gusta" en Vehículos
  const handleLike = (carId) => {
    setLikedCars((prev) =>
      prev.includes(carId)
        ? prev.filter((id) => id !== carId)
        : [...prev, carId]
    );
  };

  // Manejar Compartir Vehículo
  const handleShare = (car) => {
    const url = window.location.href + `?car=${encodeURIComponent(car.model)}`;
    setShareUrl(url);
    setShowShareModal(true);
  };

  // Copiar Enlace al Portapapeles
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      alert("¡Enlace copiado al portapapeles!");
      setShowShareModal(false);
    } catch (err) {
      alert("Error al copiar el enlace");
    }
  };

  // Ver Detalles del Vehículo
  const handleViewDetails = (car) => {
    setSelectedCar(car);
  };

  // Solicitar Cotización
  const handleSolicitarCotizacion = (car) => {
    if (car && car.link) {
      window.open(car.link, "_blank");
    } else {
      alert("Enlace no disponible para este vehículo.");
    }
  };

  // Manejar Cambio de Página
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Toggle Sidebar (Opcional)
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const pages = [
    { id: "inicio", name: "Inicio" },
    { id: "vehiculos", name: "Vehículos" },
    { id: "catalogo", name: "Catálogo" },
    { id: "nosotros", name: "Nosotros" },
    { id: "contacto", name: "Contacto" },
  ];

  // Renderizar Paginación
  const renderPagination = () => {
    if (totalPages <= 1) return null; // No mostrar paginación si solo hay una página

    // Determinar el número máximo de botones de página a mostrar
    const maxPageButtons = isMobile ? 4 : 7;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = startPage + maxPageButtons - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex flex-wrap items-center justify-center mt-8 mb-8 space-x-2">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-2 rounded-lg text-sm md:text-base ${
            currentPage === 1
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-gray-800 hover:bg-gray-700"
          } text-white`}
          aria-label="Página Anterior"
        >
          Anterior
        </button>

        {startPage > 1 && (
          <>
            <button
              onClick={() => handlePageChange(1)}
              className="px-3 py-2 rounded-lg text-sm md:text-base bg-gray-800 hover:bg-gray-700 text-white"
              aria-label={`Página 1`}
            >
              1
            </button>
            {startPage > 2 && <span className="text-white px-2">...</span>}
          </>
        )}

        {/* Botones de páginas */}
        {pageNumbers.map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-2 rounded-lg text-sm md:text-base ${
              currentPage === page
                ? "bg-red-600 cursor-default"
                : "bg-gray-800 hover:bg-gray-700"
            } text-white`}
            aria-label={`Página ${page}`}
            disabled={currentPage === page}
          >
            {page}
          </button>
        ))}

        {endPage < totalPages - 1 && <span className="text-white px-2">...</span>}

        {endPage < totalPages && (
          <button
            onClick={() => handlePageChange(totalPages)}
            className="px-3 py-2 rounded-lg text-sm md:text-base bg-gray-800 hover:bg-gray-700 text-white"
            aria-label={`Página ${totalPages}`}
          >
            {totalPages}
          </button>
        )}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 rounded-lg text-sm md:text-base ${
            currentPage === totalPages
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-gray-800 hover:bg-gray-700"
          } text-white`}
          aria-label="Página Siguiente"
        >
          Siguiente
        </button>
      </div>
    );
  };

  // Manejar Filtros Rápidos
  const handleFilter = (type) => {
    setVehicles([]); // Limpiar los resultados actuales
    setCurrentPage(1); // Reiniciar a la primera página

    if (type === "cheap") {
      setAdvancedFilters((prev) => ({
        ...prev,
        sortOrder: "asc", // Orden ascendente por precio
        combustible: "", // Sin filtro de combustible
        maxPrice: "",
        minYear: "",
        maxYear: "",
        minKilometraje: "",
        maxKilometraje: "",
        model: "",
      }));
      setQuery(""); // Limpiar búsqueda
    } else if (type === "expensive") {
      setAdvancedFilters((prev) => ({
        ...prev,
        sortOrder: "desc", // Orden descendente por precio
        combustible: "", // Sin filtro de combustible
        maxPrice: "",
        minYear: "",
        maxYear: "",
        minKilometraje: "",
        maxKilometraje: "",
        model: "",
      }));
      setQuery("");
    } else if (type === "electric") {
      setAdvancedFilters((prev) => ({
        ...prev,
        sortOrder: "asc", // Opcional: ordenar por precio ascendente
        combustible: "Eléctrico", // Filtro para coches eléctricos
        maxPrice: "",
        minYear: "",
        maxYear: "",
        minKilometraje: "",
        maxKilometraje: "",
        model: "",
      }));
      setQuery(""); // Limpiar búsqueda
    }

    fetchVehicles();
  };

  // Renderizar Panel de Filtros Avanzados
  const renderAdvancedSearch = () => (
    <AnimatePresence>
      {showAdvancedSearch && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          className="mt-4 bg-gradient-to-r from-gray-800 via-gray-900 to-black rounded-3xl p-6 backdrop-blur-xl border border-gray-700/50 overflow-hidden shadow-lg"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Filtro de Precio Máximo */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Precio Máximo (€)</label>
              <input
                type="number"
                value={advancedFilters.maxPrice}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxPrice: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Ingrese el precio máximo"
                aria-label="Precio Máximo"
              />
            </div>

            {/* Filtro de Año Mínimo */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Año Mínimo</label>
              <select
                value={advancedFilters.minYear}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    minYear: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Año Mínimo"
              >
                <option value="">Seleccionar año</option>
                {[...Array(21).keys()].map((i) => (
                  <option key={2024 - i} value={2024 - i}>
                    {2024 - i}
                  </option>
                ))}
              </select>
            </div>

            {/* Filtro de Año Máximo */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Año Máximo</label>
              <select
                value={advancedFilters.maxYear}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxYear: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Año Máximo"
              >
                <option value="">Seleccionar año</option>
                {[...Array(21).keys()].map((i) => (
                  <option key={2024 - i} value={2024 - i}>
                    {2024 - i}
                  </option>
                ))}
              </select>
            </div>

            {/* Filtro de Kilometraje Mínimo */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Kilometraje Mínimo (km)</label>
              <input
                type="number"
                value={advancedFilters.minKilometraje}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    minKilometraje: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Kilometraje mínimo"
                aria-label="Kilometraje Mínimo"
              />
            </div>

            {/* Filtro de Kilometraje Máximo */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Kilometraje Máximo (km)</label>
              <input
                type="number"
                value={advancedFilters.maxKilometraje}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    maxKilometraje: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                placeholder="Kilometraje máximo"
                aria-label="Kilometraje Máximo"
              />
            </div>

            {/* Filtro de Combustible */}
            <div className="space-y-2">
              <label className="text-gray-300 block font-semibold">Combustible</label>
              <select
                value={advancedFilters.combustible}
                onChange={(e) =>
                  setAdvancedFilters((prev) => ({
                    ...prev,
                    combustible: e.target.value,
                  }))
                }
                className="w-full px-4 py-3 bg-gray-800 text-gray-200 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 hover:bg-gray-700 transition-all"
                aria-label="Combustible"
              >
                <option value="">Todos</option>
                <option value="Gasolina">Gasolina</option>
                <option value="Diesel">Diesel</option>
                <option value="Eléctrico">Eléctrico</option>
              </select>
            </div>

            {/* Filtro de Modelo */}
            <div className="space-y-2 relative" ref={dropdownRef}>
              <label className="text-gray-300 block font-semibold">Modelo</label>
              <div className="relative">
                <input
                  type="text"
                  value={advancedFilters.model}
                  onChange={(e) => {
                    setAdvancedFilters((prev) => ({
                      ...prev,
                      model: e.target.value,
                    }));
                    setQuery(e.target.value);
                    if (e.target.value.length > 2) {
                      const filteredSuggestions = carBrands.filter((brand) =>
                        brand.toLowerCase().includes(e.target.value.toLowerCase())
                      );
                      setSuggestions(filteredSuggestions);
                    } else {
                      setSuggestions([]);
                    }
                  }}
                  className="w-full px-4 py-3 text-gray-200 bg-gray-800 rounded-2xl focus:outline-none focus:ring-4 focus:ring-red-500 transition-all placeholder-gray-400 backdrop-blur-xl"
                  placeholder="Buscarlo"
                  aria-label=""
                  ref={modelInputRef}
                />

                <button
                  type="button"
                  onClick={() => setIsModelDropdownOpen((prev) => !prev)}
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-gray-200"
                  aria-label="Toggle Modelo Dropdown"
                >
                  <FiChevronDown
                    className={
                      "transition-transform duration-200 " +
                      (isModelDropdownOpen ? "transform rotate-180" : "")
                    }
                  />
                </button>
                <AnimatePresence>
                  {isModelDropdownOpen && (
                    <motion.ul
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      className="absolute z-10 w-full bg-gray-800 backdrop-blur-xl mt-2 rounded-2xl shadow-lg max-h-60 overflow-y-auto"
                    >
                      {carBrands
                        .filter((brand) =>
                          brand.toLowerCase().includes(advancedFilters.model.toLowerCase())
                        )
                        .map((brand, index) => (
                          <li key={index}>
                            <button
                              onClick={() => handleModelSelect(brand)}
                              className="w-full text-left px-4 py-2 text-gray-200 hover:bg-gray-700 transition-colors"
                            >
                              {brand}
                            </button>
                          </li>
                        ))}

                      {carBrands.filter((brand) =>
                        brand.toLowerCase().includes(advancedFilters.model.toLowerCase())
                      ).length === 0 && (
                        <li>
                          <div className="px-4 py-2 text-gray-400">No hay marcas coincidentes</div>
                        </li>
                      )}
                    </motion.ul>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Manejar Selección de Modelo desde Dropdown
  const handleModelSelect = (brand) => {
    setAdvancedFilters((prev) => ({
      ...prev,
      model: brand,
    }));
    setQuery(brand);
    setIsModelDropdownOpen(false);
    handleSearch();
  };

  // Manejar Búsqueda
  const handleSearch = async () => {
    setIsLoading(true);
    setVehicles([]);
    setCurrentPage(1);

    let queryStr = query.trim();

    if (queryStr === "") {
      setIsLoading(false);
      setError("Por favor ingresa un término de búsqueda.");
      return;
    }

    // Realiza la búsqueda solo en la columna `model`
    let searchQuery = supabase
      .from("VEHÍCULOS2_duplicate con datos")
      .select("*", { count: "exact" })
      .ilike("model", `%${queryStr}%`)
      .range(0, pageSize - 1);

    // Aplicar filtros adicionales
    const {
      maxPrice,
      minYear,
      maxYear,
      combustible,
      sortOrder,
      minKilometraje,
      maxKilometraje,
      model,
    } = advancedFilters;

    if (maxPrice) {
      searchQuery = searchQuery.lte("precio", parseInt(maxPrice));
    }
    if (combustible) {
      searchQuery = searchQuery.eq("combustible", combustible);
    }
    if (model) {
      searchQuery = searchQuery.ilike("model", `%${model}%`);
    }
    if (minYear) {
      searchQuery = searchQuery.gte("year", parseInt(minYear));
    }
    if (maxYear) {
      searchQuery = searchQuery.lte("year", parseInt(maxYear));
    }
    if (minKilometraje) {
      searchQuery = searchQuery.gte("kilometraje", parseInt(minKilometraje));
    }
    if (maxKilometraje) {
      searchQuery = searchQuery.lte("kilometraje", parseInt(maxKilometraje));
    }

    // Validaciones
    if (minYear && maxYear && parseInt(minYear) > parseInt(maxYear)) {
      setError("El Año Mínimo no puede ser mayor que el Año Máximo.");
      setIsLoading(false);
      return;
    } else {
      setError("");
    }

    if (
      minKilometraje &&
      maxKilometraje &&
      parseInt(minKilometraje) > parseInt(maxKilometraje)
    ) {
      setError("El Kilometraje Mínimo no puede ser mayor que el Kilometraje Máximo.");
      setIsLoading(false);
      return;
    } else {
      setError("");
    }

    // Aplicar ordenamientos
    if (sortOrder) {
      searchQuery = searchQuery.order("precio", { ascending: sortOrder === "asc" });
    }

    try {
      const { data, count, error } = await searchQuery;

      if (error) {
        console.error("Error al buscar vehículos:", error);
        setError("Hubo un problema al buscar los vehículos.");
      } else {
        if (data.length === 0) {
          setError("No se encontraron vehículos que cumplan con los filtros.");
        } else {
          setVehicles(data);
          setTotalPages(Math.ceil(count / pageSize));
          setError("");
        }
      }
    } catch (err) {
      console.error("Error en la consulta de búsqueda:", err);
      setError("Hubo un problema al buscar los vehículos.");
    }

    setIsLoading(false);
  };

  // Manejar Click en Sugerencia de Búsqueda
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setAdvancedFilters((prev) => ({
      ...prev,
      model: suggestion,
    }));
    handleSearch();
  };

  // Manejar Cambio en el Campo de Búsqueda
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setAdvancedFilters((prev) => ({
      ...prev,
      model: value,
    }));
    if (value.length > 2) {
      const filteredSuggestions = frequentSearches.filter((search) =>
        search.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  // Renderizar Sugerencias de Búsqueda
  const renderSuggestions = () => (
    <div className="mt-2 flex flex-wrap gap-2">
      {suggestions.map((search, index) => (
        <button
          key={index}
          onClick={() => handleSuggestionClick(search)}
          className="bg-gray-700 text-white px-3 py-1 rounded-full hover:bg-gray-600 transition-all"
        >
          {search}
        </button>
      ))}
    </div>
  );

  // Renderizar Modal de Detalles del Vehículo
  const renderCarDetailModal = () => (
    <AnimatePresence>
      {selectedCar && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          onClick={() => setSelectedCar(null)}
        >
          <motion.div
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.95 }}
            className="bg-gray-900 rounded-2xl p-6 max-w-lg w-full text-white shadow-lg overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            {/* Contenido del modal */}
            <div className="space-y-6">
              {/* Encabezado con imagen y botón de cerrar */}
              <div className="relative">
                <img
                  src={selectedCar.image}
                  alt={selectedCar.model}
                  className="w-full h-64 object-cover rounded-lg"
                  loading="lazy"
                />
                <button
                  onClick={() => setSelectedCar(null)}
                  className="absolute top-4 right-4 p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                  aria-label="Cerrar"
                >
                  <FaTimes className="text-white" />
                </button>
              </div>

              {/* Información básica */}
              <div>
                <h2 className="text-3xl font-bold">{selectedCar.model}</h2>
                <p className="text-2xl font-semibold text-red-500">€{selectedCar.precio}</p>
              </div>

              {/* Información detallada */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Año */}
                <div className="flex items-center space-x-3">
                  <FaCalendarAlt className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                  <div>
                    <h3 className="text-lg font-semibold">Año</h3>
                    <p className="text-gray-300">{selectedCar.year}</p>
                  </div>
                </div>
                {/* Color */}
                <div className="flex items-center space-x-3">
                  <FaTag className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                  <div>
                    <h3 className="text-lg font-semibold">Color</h3>
                    <p className="text-gray-300">{selectedCar.color}</p>
                  </div>
                </div>
                {/* Combustible */}
                <div className="flex items-center space-x-3">
                  <FaGasPump className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                  <div>
                    <h3 className="text-lg font-semibold">Combustible</h3>
                    <p className="text-gray-300">{selectedCar.combustible}</p>
                  </div>
                </div>
                {/* Kilometraje */}
                <div className="flex items-center space-x-3">
                  <FaRoad className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                  <div>
                    <h3 className="text-lg font-semibold">Kilometraje</h3>
                    <p className="text-gray-300">{selectedCar.kilometraje} km</p>
                  </div>
                </div>
                {/* Cilindrada */}
                {selectedCar.cilindrada && (
                  <div className="flex items-center space-x-3">
                    <FaCar className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                    <div>
                      <h3 className="text-lg font-semibold">Cilindrada</h3>
                      <p className="text-gray-300">{selectedCar.cilindrada} cc</p>
                    </div>
                  </div>
                )}
                {/* Caballos de Fuerza */}
                {selectedCar.caballos && (
                  <div className="flex items-center space-x-3">
                    <FaFireAlt className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                    <div>
                      <h3 className="text-lg font-semibold">Caballos de Fuerza</h3>
                      <p className="text-gray-300">{selectedCar.caballos} CV</p>
                    </div>
                  </div>
                )}
                {/* Garantía */}
                {selectedCar.garantia && (
                  <div className="flex items-center space-x-3">
                    <FaExclamation className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                    <div>
                      <h3 className="text-lg font-semibold">Garantía</h3>
                      <p className="text-gray-300">{selectedCar.garantia}</p>
                    </div>
                  </div>
                )}
                {/* Etiqueta */}
                {selectedCar.etiqueta && (
                  <div className="flex items-center space-x-3">
                    <FaTag className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                    <div>
                      <h3 className="text-lg font-semibold">Etiqueta</h3>
                      <p className="text-gray-300">{selectedCar.etiqueta}</p>
                    </div>
                  </div>
                )}
                {/* IVA Incluido */}
                {selectedCar.iva !== undefined && (
                  <div className="flex items-center space-x-3">
                    <FaDollarSign className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                    <div>
                      <h3 className="text-lg font-semibold">IVA Incluido</h3>
                      <p className="text-gray-300">{selectedCar.iva ? "Sí" : "No"}</p>
                    </div>
                  </div>
                )}
              </div>

              {/* Botones de Acción */}
              <div className="flex flex-wrap gap-4 justify-center mt-6">
                {/* Botón de Contacto */}
                <button
                  onClick={() => handleSolicitarCotizacion(selectedCar)}
                  className="flex items-center space-x-2 bg-gradient-to-r from-red-600 to-red-800 text-white py-3 px-6 rounded-xl hover:shadow-lg hover:shadow-red-500/30 transition-all"
                  aria-label={`Contacto para ${selectedCar.model}`}
                >
                  <FaEnvelope className="text-lg" /> {/* Tamaño uniforme */}
                  <span>Contacto</span>
                </button>
                {/* Botón de Compartir */}
                <button
                  onClick={() => handleShare(selectedCar)}
                  className="flex items-center space-x-2 bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all"
                  aria-label={`Compartir ${selectedCar.model}`}
                >
                  <FaShare className="text-lg" /> {/* Tamaño uniforme */}
                  <span>Compartir</span>
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  // Función para Volver al Inicio
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="min-h-screen bg-black text-white relative overflow-x-hidden">
      {/* Header */}
      <motion.header
        className={`fixed w-full top-0 z-50 bg-black backdrop-blur-md transition-all duration-300 border-b border-[#202630]`}
      >
        <nav className="container mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            {/* Reemplazamos el texto por una imagen del logotipo con dimensiones 144x23 */}
            <motion.img
              src="/img/accarlogo.svg"
              alt="Ac Car Logo"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              whileHover={{ scale: 1.05 }}
              className="w-[144px] h-[23px] cursor-pointer"
              onClick={() => setActivePage("inicio")}
            />

            <div className="hidden lg:flex items-center space-x-8">
              {pages.map((page) => (
                <motion.button
                  key={page.id}
                  className={`px-4 py-2 rounded-full transition-colors duration-300 text-sm ${
                    activePage === page.id
                      ? "bg-white/20"
                      : "hover:bg-white/10"
                  }`}
                  onClick={() => setActivePage(page.id)}
                  whileHover={{
                    scale: 1.1,
                    backgroundColor: "rgba(255,0,0,0.3)",
                  }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span>{page.name}</span>
                </motion.button>
              ))}
            </div>

            <div className="flex items-center space-x-4">
              <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <FiSearch className="text-xl cursor-pointer" />
              </motion.div>
              <div className="flex items-center space-x-4">
                <motion.div
                  whileHover={{ scale: 1.1, color: "#ff0000" }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FiHeartOutline className="text-xl cursor-pointer transition-colors duration-300" />
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.1, color: "#ff0000" }}
                  whileTap={{ scale: 0.9 }}
                >
                  <FiUser className="text-xl cursor-pointer transition-colors duration-300" />
                </motion.div>
              </div>
              <motion.button
                className="lg:hidden text-white"
                onClick={toggleSidebar}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                aria-label="Toggle Sidebar"
              >
                <FiMenu size={24} />
              </motion.button>
            </div>
          </div>
        </nav>
      </motion.header>

      {/* Sidebar */}
      <AnimatePresence>
        {isSidebarOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
              onClick={toggleSidebar}
            />
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "spring", bounce: 0, duration: 0.4 }}
              className="fixed inset-0 bg-black z-50 p-6 flex flex-col"
            >
              <div className="flex justify-end mb-6">
                <button onClick={toggleSidebar} className="focus:outline-none">
                  <FiX size={24} />
                </button>
              </div>
              <div className="flex flex-col space-y-8 items-center justify-center flex-grow">
                <motion.div className="relative w-full max-w-md">
                  <input
                    type="text"
                    placeholder="Buscar vehículos..."
                    className="w-full py-3 px-4 pl-10 bg-white/10 rounded-full focus:outline-none focus:ring-2 focus:ring-white/50 text-lg"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
                </motion.div>
                {pages.map((page, index) => (
                  <motion.button
                    key={page.id}
                    className={`px-6 py-4 rounded-full transition-colors duration-300 text-lg ${
                      activePage === page.id
                        ? "bg-white/20"
                        : "hover:bg-white/10"
                    } w-full max-w-md`}
                    onClick={() => {
                      setActivePage(page.id);
                      toggleSidebar();
                    }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <span>{page.name}</span>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Contenido principal */}
      <div className="pt-24 px-4 sm:px-8 flex flex-col items-center justify-center">
        {/* Título */}
        <motion.h1
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="text-4xl font-bold text-center text-white mb-6"
        >
          ACCAR STOCK
        </motion.h1>

        {/* Barra de Búsqueda y Filtros */}
        <div className="w-full max-w-5xl mb-8">
          <div className="relative backdrop-blur-xl bg-gray-800/30 rounded-3xl shadow-2xl transition-all duration-300 overflow-hidden border border-gray-700/50">
            <div className="p-4 sm:p-6">
              <div className="relative flex flex-col sm:flex-row items-center gap-4">
                {/* Botón de Adjuntar Archivo */}
                <div className="hidden sm:block">
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    accept="image/*,video/*,.pdf,.doc,.docx"
                    onChange={handleFileUpload}
                    multiple
                  />
                  <button
                    onClick={() => fileInputRef.current.click()}
                    className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                    aria-label="Adjuntar archivo"
                  >
                    <FaPaperclip className="text-red-500 text-lg group-hover:scale-110 transition-transform" /> {/* Ícono rojo y tamaño uniforme */}
                  </button>
                </div>
                {/* Campo de Búsqueda */}
                <div className="relative flex-1 w-full">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                      setCurrentPage(1); // Reiniciar a la primera página al cambiar la consulta
                    }}
                    className="w-full px-4 sm:px-6 py-3 sm:py-4 text-gray-200 bg-gray-700/50 rounded-xl focus:outline-none focus:ring-2 focus:ring-red-500 transition-all placeholder-gray-400 backdrop-blur-xl"
                    placeholder="Buscar vehículos de lujo (e.g., 'Ferrari más barato')"
                    aria-label="Campo de búsqueda"
                  />
                  <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                    {isLoading ? (
                      <FaSpinner className="animate-spin text-red-500 text-xl" />
                    ) : (
                      <FaSearch className="text-gray-400 text-xl" />
                    )}
                  </div>
                </div>
                {/* Botón de Búsqueda Avanzada */}
                <button
                  onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  className="p-3 sm:p-4 bg-red-500/20 rounded-xl hover:bg-red-500/30 transition-colors group"
                  aria-label="Búsqueda avanzada"
                >
                  <FaFilter
                    className={
                      "text-red-500 text-lg group-hover:scale-110 transition-transform " +
                      (showAdvancedSearch ? "rotate-180" : "")
                    }
                  />
                </button>
              </div>
              {/* Sugerencias de Búsqueda */}
              {renderSuggestions()}
              {/* Previsualización de Archivos */}
              <FilePreview />
              {/* Búsqueda Avanzada */}
              {renderAdvancedSearch()}
            </div>
          </div>

          {/* Botones de Filtros Rápidos */}
          <div className="mt-6 flex flex-wrap justify-center gap-3">
            <button
              onClick={() => handleFilter("expensive")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.sortOrder === "desc" && !advancedFilters.combustible
                  ? "bg-red-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Más Caros"
            >
              <FaDollarSign className="text-yellow-500 text-lg" /> {/* Ícono con color específico y tamaño uniforme */}
              <span>Coches Más Caros</span>
            </button>
            <button
              onClick={() => handleFilter("electric")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.combustible === "Eléctrico"
                  ? "bg-blue-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Eléctricos"
            >
              <FaBolt className="text-blue-500 text-lg" /> {/* Ícono con color específico y tamaño uniforme */}
              <span>Coches Eléctricos</span>
            </button>
            <button
              onClick={() => handleFilter("cheap")}
              className={`flex items-center space-x-2 px-3 py-1.5 rounded-full text-white text-sm ${
                advancedFilters.sortOrder === "asc" && !advancedFilters.combustible
                  ? "bg-purple-500"
                  : "bg-gray-800 hover:bg-gray-700"
              } transition-all shadow-lg`}
              aria-label="Filtrar Coches Más Baratos"
            >
              <FaTag className="text-purple-500 text-lg" /> {/* Ícono con color específico y tamaño uniforme */}
              <span>Coches Más Baratos</span>
            </button>
          </div>
        </div>

        {/* Mostrar mensaje si no hay vehículos */}
        {vehicles.length === 0 && !isLoading && (
          <p className="text-gray-400 text-lg mt-8">
            No se encontraron vehículos que coincidan con tu búsqueda.
          </p>
        )}

        {/* Lista de Vehículos */}
        <div className="w-full max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {vehicles.map((car, index) => (
            <motion.div
              key={car.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-gray-800/50 rounded-2xl overflow-hidden border border-gray-700/50 backdrop-blur-xl hover:shadow-xl hover:shadow-red-500/10 transition-all duration-300 h-full flex flex-col"
            >
              <div className="relative group h-48">
                <img
                  src={car.image}
                  alt={car.model}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  loading="lazy"
                />
                <div className="absolute top-4 right-4 space-x-2">
                  <button
                    onClick={() => handleLike(car.id)}
                    className={`p-2 ${
                      likedCars.includes(car.id)
                        ? "bg-red-500"
                        : "bg-gray-900/80"
                    } rounded-full hover:bg-red-500/80 transition-colors`}
                    aria-label={`Me gusta ${car.model}`}
                  >
                    <FaHeart className="text-white text-sm" />
                  </button>
                  <button
                    onClick={() => handleShare(car)}
                    className="p-2 bg-gray-900/80 rounded-full hover:bg-blue-500/80 transition-colors"
                    aria-label={`Compartir ${car.model}`}
                  >
                    <FaShare className="text-white text-sm" />
                  </button>
                </div>
              </div>
              <div className="p-6 space-y-4 flex flex-col justify-between flex-grow">
                <div>
                  <h3 className="text-xl font-bold text-white">
                    {car.model}
                  </h3>
                  <p className="text-lg font-bold text-red-500">
                    €{car.precio}
                  </p>
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <div className="flex items-center space-x-2">
                      <FaCalendarAlt className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                      <span className="text-gray-300">Año: {car.year}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaTag className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                      <span className="text-gray-300">Color: {car.color}</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 mt-2">
                    <div className="flex items-center space-x-2">
                      <FaGasPump className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                      <span className="text-gray-300">{car.combustible}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <FaRoad className="text-red-500 text-lg" /> {/* Ícono rojo y tamaño uniforme */}
                      <span className="text-gray-300">{car.kilometraje} km</span>
                    </div>
                  </div>
                </div>
                <div className="pt-4 border-t border-gray-700 mt-auto space-y-2">
                  <button
                    onClick={() => handleSolicitarCotizacion(car)}
                    className="w-full bg-gradient-to-r from-red-600 to-red-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-red-500/30 transition-all"
                  >
                    Solicitar Información
                  </button>
                  <button
                    onClick={() => handleViewDetails(car)}
                    className="w-full bg-gray-700 text-white py-2 rounded-xl hover:bg-red-600 transition-all"
                    aria-label={`Ver Detalles de ${car.model}`}
                  >
                    Ver Detalles
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Renderizar los controles de paginación */}
        {renderPagination()}

        {/* Modal de Detalles del Vehículo */}
        {renderCarDetailModal()}

        {/* Modal de Compartir */}
        <AnimatePresence>
          {showShareModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.95 }}
                className="bg-gray-800 rounded-2xl p-6 max-w-md w-full"
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold text-white">
                    Compartir Vehículo
                  </h3>
                  <button
                    onClick={() => setShowShareModal(false)}
                    className="p-2 hover:bg-gray-700 rounded-full"
                    aria-label="Cerrar Compartir"
                  >
                    <FaTimes className="text-gray-400" />
                  </button>
                </div>
                <div className="space-y-4">
                  <input
                    type="text"
                    value={shareUrl}
                    readOnly
                    className="w-full bg-gray-700 rounded-lg px-4 py-2 text-white"
                    aria-label="Enlace de Compartir"
                  />
                  <button
                    onClick={copyToClipboard}
                    className="w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-2 rounded-xl hover:shadow-lg hover:shadow-blue-500/30 transition-all"
                  >
                    Copiar Enlace
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Botones de Subir y Chatbot */}
      <motion.div
        className={`fixed bottom-6 md:bottom-8 right-6 md:right-8 flex flex-row gap-2 z-50 ${
          isMobile && isChatbotOpen ? "hidden" : "flex"
        }`}
      >
        {/* Botón de Subir */}
        <motion.button
          onClick={scrollToTop}
          className="p-2 bg-blue-500 rounded-full hover:bg-blue-600 transition-colors"
          aria-label="Subir al Inicio"
        >
          <FiArrowUp className="text-white text-2xl md:text-3xl" />
        </motion.button>

        {/* Botón de Chatbot */}
        <ChatbotButton setIsChatbotOpen={setIsChatbotOpen} />
      </motion.div>
    </div>
  );
};

export default SearchComponent;
